@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a,
  button {
    @apply outline-primary;
    @apply outline-offset-4;
  }
}

/* Hide input search cross */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
